import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Button} from 'components/general/button';
import {Heading} from 'components/general/heading';
import {Modal} from 'components/general/modal';
import {alert} from 'alert';
import {BsPlus,BsDownload} from 'react-icons/bs';
import './licenses.css';
import {API_URL, MANAGER_URL} from 'config';
import { DashCard } from '../../components/card';
import { Table } from 'components/general/table';

const ManageLicenses = ()=>{
    let {auth} = useContext(AuthContext);
    let [licenses, setLicenses] = useState(false);
    let [loading, setLoading] = useState(true);
    let [total, setTotal] = useState(0);
    let [target, setTarget] = useState(false);
    const modal = useRef(null);
    let now = new Date().getTime();
    let [saving, setSaving] = useState(false);

    const getLicenses = async ()=>{
        let url = new URL(API_URL + `/v1/customer/license/`);
        let res = await auth.ajax(url);
        if(res.ok){
            let now = new Date().getTime()
            let licenses = res.user.licenses;
            if(licenses.length){
                let on_hold = []
                let expired = []
                let claimed = []
                let unclaimed = []
                for(let license of licenses){
                    if(license.on_hold){
                        on_hold.push(license)
                        continue
                    }

                    if(parseInt(license.expires) != -1 && parseInt(license.expires) - now < 0){
                        expired.push(license)
                        continue
                    }

                    if(license.shop === null){
                        unclaimed.push(license)
                        continue
                    }

                    claimed.push(license)
                }
                setLicenses({on_hold, expired, unclaimed, claimed});
                setTotal(licenses.length);
            } else {
                setLicenses(false);
                alert.error('No licenses assigned to your e-mail found.');
            }
            return setLoading(false);
        } else {
            return alert.error('Error getting licenses');
        }
    }

    useEffect(
        ()=>{
            getLicenses();
        },
        []
    )

    const submitLicense = async ()=>{
        let domain = document.getElementById('newLicense').value;
        domain = domain.replace(/https?:\/\//gi, '');
        try{
            let url = new URL('https://' + domain.toLowerCase());
            let host = url.host;
            let temphost = host.split('.')
            if(temphost[temphost.length - 2] !== 'myshopify'){
                return alert.error('You must use a \'myshopify.com\' domain.')
            }
            let temptarget = target;
            temptarget.shop = host;
            setTarget(temptarget);
            updatelicense();
        }catch(err){
            alert.error('Invalid domain format.')
        }
    }

    const updatelicense = async ()=>{
        setSaving(true);
        let {shop, id} = target;
        let res = await auth.ajax(API_URL+'/v1/customer/license/update', 
            {
                method: 'POST', 
                body: JSON.stringify({domain: shop, id})
            }
        );
        setSaving(false);
        if(!res.ok){
            return alert.error(res.error);
        }
        alert.success('License updated successfully');
        setTarget(false);
        modal.current.closeModal();
        await getLicenses();
    }

    const getLicense = async (id)=>{
        let res = await auth.ajax(API_URL+'/v1/customer/license/'+id);
        if(res.ok){
            setTarget(res.license);
            modal.current.openModal();
        } else {
            alert.error(JSON.stringify(res.error));
        }
    }

    return <React.Fragment>
        <React.Fragment>
            <Heading loading={loading} text={`Manage licenses (${total})`}>
                <a target="_blank" href="https://checkout.boostertheme.com">
                    <Button type="primary"><BsPlus /> Buy another license</Button>
                </a>
            </Heading>
            <div className="page__content">
                {licenses &&
                <>
                {
                    licenses.unclaimed.length &&
                    <>
                    <Heading margin secondary text={`Unclaimed licenses (${licenses.unclaimed.length})`} />
                    <Table header={['Order ID', 'Shop', 'Expires', '']}>
                    {
                        licenses.unclaimed.map((v)=>{
                            let expires = v.expires == -1 && 'Never' || Math.floor((parseInt(v.expires) - now)/1000/86400);
                            if(expires < 0){
                                expires = 'Expired';
                            } else if(expires > 0){
                                expires += ' days';
                            }
                            let last_edit = v.last_user_update == 0 ? -1 : Math.floor((now - parseInt(v.last_user_update))/1000/86400);
                            let edit_disabled = last_edit < 7 && last_edit != -1;
    
                            return (
                                <tr>
                                    <td>{v.order_id}</td>
                                    <td>{v.shop === null && 'No Shop assigned' || v.shop}</td>
                                    <td>{expires}</td>
                                    <td>
                                        <Button type="text" onClick={
                                           ()=>{
                                            if(edit_disabled || expires == 'Expired' || v.on_hold){
                                                 return;
                                             } 
                                             getLicense(v.id);
                                         } 
                                        }>
                                            Activate License
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </Table>
                    </> || null
                }
                {
                    licenses.claimed.length &&
                    <>
                    <Heading margin secondary text={`Claimed licenses (${licenses.claimed.length})`} />
                    <Table header={['Order ID', 'Shop', 'Expires', '']}>
                    {
                        licenses.claimed.map((v)=>{
                            let expires = v.expires == -1 && 'Never' || Math.floor((parseInt(v.expires) - now)/1000/86400);
                            if(expires < 0){
                                expires = 'Expired';
                            } else if(expires > 0){
                                expires += ' days';
                            }
                            let last_edit = v.last_user_update == 0 ? -1 : Math.floor((now - parseInt(v.last_user_update))/1000/86400);
                            let edit_disabled = last_edit < 7 && last_edit != -1;
    
                            return (
                                <tr>
                                    <td>{v.order_id}</td>
                                    <td>{v.shop === null && 'No Shop assigned' || v.shop}</td>
                                    <td>{expires}</td>
                                    <td>
                                        <Button type="text" onClick={
                                           ()=>{
                                            if(edit_disabled || expires == 'Expired' || v.on_hold){
                                                 return;
                                             } 
                                             getLicense(v.id);
                                         } 
                                        }
                                        disabled={edit_disabled}>
                                            {!edit_disabled && 'Edit license' || 'Edit available in ' + (7 - last_edit) + ' days' }
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </Table>
                    </> || null
                }
                {
                    licenses.on_hold.length &&
                    <>
                    <Heading margin secondary text={`Licenses on hold (${licenses.on_hold.length})`} />
                    <Table disabled header={['Order ID', 'Shop', 'Expires', '']}>
                    {
                        licenses.on_hold.map((v)=>{
                            let expires = v.expires == -1 && 'Never' || Math.floor((parseInt(v.expires) - now)/1000/86400);
                            if(expires < 0){
                                expires = 'Expired';
                            } else if(expires > 0){
                                expires += ' days';
                            }
    
                            return (
                                <tr>
                                    <td>{v.order_id}</td>
                                    <td>{v.shop === null && 'No Shop assigned' || v.shop}</td>
                                    <td>{expires}</td>
                                    <td>
                                        <Button disabled type="text">
                                            License on hold
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </Table>
                    </> || null
                }
                {
                    licenses.expired.length &&
                    <>
                    <Heading margin secondary text={`Expired licenses (${licenses.expired.length})`} />
                    <Table disabled header={['Order ID', 'Shop', 'Expires', '']}>
                    {
                        licenses.expired.map((v)=>{
                            let expires = v.expires == -1 && 'Never' || Math.floor((parseInt(v.expires) - now)/1000/86400);
                            if(expires < 0){
                                expires = 'Expired';
                            } else if(expires > 0){
                                expires += ' days';
                            }

                            return (
                                <tr>
                                    <td>{v.order_id}</td>
                                    <td>{v.shop === null && 'No Shop assigned' || v.shop}</td>
                                    <td>{expires}</td>
                                    <td>
                                        <Button disabled type="text">
                                            Expired license
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </Table>
                    </> || null
                }
                </> || 'Loading...'
                }
            </div>
            <Modal loading={saving} confirmText={saving && 'Saving...' || 'Update license'} title={'Editing license: #' + target.order_id} closeCallback={()=>setTarget(false)} cancelCallback={()=>setTarget(false)} confirmCallback={()=>submitLicense()} ref={modal}>
                <span className="input__label">Current domain</span>
                <input className="input--style" disabled value={target.shop}></input>
                <span style={{marginTop: '12px'}} className="input__label">New domain</span>
                <input id="newLicense" className="input--style"></input>
            </Modal>
        </React.Fragment>
    </React.Fragment>
}

export {
    ManageLicenses
}