import React from 'react';
import Icon from './icon';

const Sidebar = (props)=>{

    return (
    <div className="frame__sidebar">
        <div className="frame__logo">
            <img src={process.env.PUBLIC_URL + '/mini-logo--accent.png'} />
        </div>
        <div className="frame__sidebar__icons">
            <Icon icon='BsLockFill' href="/" matches={["/"]} tooltip="Manage all licenses">
                <Icon hide secondary icon='BsTools' matches={["/manage/:license"]} tooltip="Manage a license" />
            </Icon>
        </div>
        <div className="frame__sidebar__icons">
            <Icon icon='BsBrush' href="/themes" tooltip="Themes">
            </Icon>
        </div>
    </div>
    )
}

export {
    Sidebar
}