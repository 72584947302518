import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import {Routes} from 'router/index';
import AuthContext from 'auth/context';
import Notifications from 'react-notify-toast';
import {authService} from 'auth';

function App() {
  let [currentUser, setUser] = useState();

  const authContext = {auth: authService, currentUser}

  useEffect(
    ()=>{
      authService.userSubject.subscribe(
        (value)=>{
          setUser(value);
        }
      )
    }
  ,[])

  useEffect(
    ()=>{
      setInterval(()=>{
        let exp = localStorage.getItem('tokenExp') || false;
        let date = new Date().getTime();
        if(!exp || exp < date){
          return;
        }
        let min = document.getElementById('min');
        let sec = document.getElementById('sec');

        let diff = Math.abs((exp - date) / 1000);
        let mins = Math.floor(diff / 60) % 60;
        diff -= mins * 60;
        let secs = Math.floor(diff);
        try{
          min.innerText = mins;
          sec.innerText = secs;
        }catch(err){
          return;
        }
        
      }, 1000)
    }
  )

  return (
    <React.Fragment>
      <Notifications />
      <AuthContext.Provider value={authContext}>
        <Router>
        <div className="App">
          <Routes />
        </div>
        </Router>
      </AuthContext.Provider>
    </React.Fragment>
  );
}

export default App;
