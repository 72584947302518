import React from 'react';
import './frame.css';
import {Sidebar} from './sidebar/index';
import {Header} from './header';

const Frame = (props) => {
    return (
    <div className="frame">
        <Sidebar />
        <Header /> 
        <div className="frame__children">
            {props.children}
        </div>
    </div>
    )
}

export {
    Frame
}

