import React, {useState, useEffect} from 'react';
import './login.css';
import {authService} from 'auth';
import {alert} from 'alert';

const LoginForm = (props)=>{
    let [error, setError] = useState(false);
    let [logging, setLogging] = useState(false);
    let {match} = props;

    const login = async ()=>{
        let token = match.params.token;
        if(!token){
            return;
        }
        setLogging(true);
        alert.warning('Logging you in..');
        let res = await authService.login(token);
        if(!res.ok){
            alert.error(JSON.stringify(res.error));
            return setLogging(false);
        }
        localStorage.setItem('tokenExp', new Date().getTime() + 900000);
        alert.success('Logged in successfully!');
    }

    useEffect(()=>{
        login();
    },
    []
    )

    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        let email = params.get('email');
        if(!email){
            return;
        }
        document.getElementById('email').value = email;
    },
    []
    );

    const reqLogin = async (e)=>{
        e.preventDefault();
        setError(false);
        let button = document.getElementById('submit');
        button.disabled = "true";
        let email = document.getElementById('email').value.trim();
        let res = await authService.reqLogin({email});
        button.disabled = false;
        if(!res.ok){
            return alert.error(res.error)
        }
        alert.success('Check your e-mail for a login URL.');
    }
    
    return (
        <div className="login-form__holder">
            <form onSubmit={reqLogin} className="login-form">
                <h1 className="login__h1">Login</h1>
                <h2 className="color--secondary login__h2">sign in to continue</h2>
                {
                    error &&
                    <div className="form__error">
                        {error}
                    </div>
                }
                <div className="form__combo">
                    <span className="form__label">
                        E-mail
                    </span>
                    <input disabled={logging} id="email" className="form__input login__form__input" type="text" />
                </div>
                <button disabled={logging} id="submit" className="button button--primary login__form__button" type="submit">Login</button>
            </form>
            <img className="form__logo" src={process.env.PUBLIC_URL + '/logo--secondary.png'} />
        </div>
    )
}

export {
    LoginForm
}