import {Switch, Route} from 'react-router-dom';
import {UserRoute} from './protected';
import {Login} from 'pages/login';
import React from 'react';
import {Frame} from 'components/frame';
import { ManageLicenses } from 'pages/licenses';
import {ManageLicense} from 'pages/licenses/manage';
import { ManageThemes } from "pages/app_themes";

const Routes = ()=>{
    return <Switch>
        <Route exact render={(props)=>
            <Login {...props} />} path="/login/:token?/:utm?"
        />
        <UserRoute path="/themes">
            <Frame>
                <ManageThemes />
            </Frame>
        </UserRoute>
        <UserRoute path="/">
            <Frame>
                <ManageLicenses />
            </Frame>
        </UserRoute>
    </Switch>
}

export {
    Routes
}