import React from 'react';
import './button.css';

const Button = ({onClick, type, className, children, inProgress, ...props})=>{
    let inProgressText = inProgress && inProgress.text || 'Loading...';

    let bold = props.bold && 'button--bold' || '';

    return (
        <button 
        className={`button button--${type} ${bold} ${className}`}
        onClick={onClick} {...props}>
            {inProgress && inProgress.state && inProgressText || children}
        </button>
    )
}

export {
    Button
}