import React from 'react';
import './heading.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner'

const Heading = (props)=>{

    return <React.Fragment>
    {
        props.secondary &&
        <h2 className={"page__header " + (props.margin && 'page__header--inverse' || '')}>
            <span>
                {props.text}
            </span>
            {props.loading &&
            <span className="page__haeder__loader">
                <Loader
                    type="Grid"
                    color="#0054FC"
                    height={35}
                    width={35}
                />
            </span>}
            <div className="page__header__actions">
                {props.children}
            </div>
        </h2> ||
        <h1 className="page__header">
            <span>
                {props.text}
            </span>
            {props.loading &&
            <span className="page__haeder__loader">
                <Loader
                    type="Grid"
                    color="#0054FC"
                    height={35}
                    width={35}
                />
            </span>}
            <div className="page__header__actions">
                {props.children}
            </div>
        </h1>
    }
    </React.Fragment> 
}

export {
    Heading
}