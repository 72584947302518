import {Route, Redirect} from 'react-router-dom';
import React, {useState, useContext, useEffect} from 'react';
import AuthContext from 'auth/context';
import RouteContext from './context';

const UserRoute = ({ children, role, ...rest })=>{
    let {currentUser} = useContext(AuthContext);

    return (
      <Route exact
        {...rest}
        render={
            (routeprops) =>
            currentUser ? (
            <RouteContext.Provider value={{...routeprops}}>
                {children}
            </RouteContext.Provider>
          ) : (
            <Redirect
              to={`/login`}
            />
          )
        }
      />
    );
  }

export {
    UserRoute
}