import React, {useContext, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {LoginForm} from './form';
import AuthContext from 'auth/context';

const Login = ({match})=>{
    let {currentUser} = useContext(AuthContext);

    return (
        !currentUser &&
        <LoginForm match={match} /> ||
        <Redirect to='/' />
    )
}

export {
    Login
}