import {Link, useLocation} from 'react-router-dom';
import * as Icons from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';

const Icon = ({children, ...props})=>{
    let location = useLocation();
    let Icon = Icons[props.icon];
    let [activeChild, setActiveChild] = useState(false);
    let [matches, setMatches] = useState(false);

    const checkMatches = (props)=>{
        let matches = props.matches || [];
        for(let match of matches){
            if(matchPath(location.pathname, {path: match, exact: true}) != null){
                return true;
            }
        }
        return false;
    }

    const checkChildren = (propsChildren)=>{
        let children = React.Children.toArray(propsChildren);
        for(let child of children){
            if(checkMatches(child.props)){
                return true;
            }
        }
        return false;
    }

    useEffect(
        ()=>{
            setActiveChild(checkChildren(children));
            setMatches(checkMatches(props));
        },
        [location]
    )

    let active = location.pathname == props.href || activeChild || matches;
    let hide = props.hide && !active;
    let secondary = props.secondary && '--secondary' || '';
    let secondaryIcon = props.secondary && 'frame__sidebar__icon--secondary';
    
    return (
        <React.Fragment>
            {
            !hide &&
            <div>
                <Link className={`frame__sidebar__icon__holder ${active && 'frame__sidebar__icon__holder'+secondary+'--active'}`} to={props.href}>
                    <Icon className={`frame__sidebar__icon ${secondaryIcon} ${active && 'frame__sidebar__icon--active'}`} />
                    <div className="frame__sidebar__icon__tooltip">{props.tooltip}</div>
                </Link>
                {active && children}
            </div>
            }
        </React.Fragment>
    )
}

export default Icon;