import React, {useEffect, useState, useContext, useRef} from 'react';
import AuthContext from 'auth/context';
import {Heading} from 'components/general/heading';
import {alert} from 'alert';
import {Table} from 'components/general/table';
import {BsDownload} from 'react-icons/bs';
import {API_URL, MANAGER_URL} from 'config';

const ManageThemes = () => {
    let {auth} = useContext(AuthContext);
    let [themes, setThemes] = useState([]);
    let [loading, setLoading] = useState(true);
    let [licenses, setLicenses] = useState(false);
    const getLicenses = async () => {
        let url = new URL(API_URL + `/v1/customer/license/`);
        let res = await auth.ajax(url);
        if (res.ok) {
            let now = new Date().getTime()
            let licenses = res.user.licenses;
            if(licenses.length){
                let on_hold = []
                let expired = []
                let claimed = []
                let unclaimed = []
                for(let license of licenses){
                    if(license.on_hold){
                        on_hold.push(license)
                        continue
                    }

                    if(parseInt(license.expires) != -1 && parseInt(license.expires) - now < 0){
                        expired.push(license)
                        continue
                    }

                    if(license.shop === null){
                        unclaimed.push(license)
                        continue
                    }

                    claimed.push(license)
                }
                setLicenses({on_hold, expired, unclaimed, claimed});
            } else {
                setLicenses(false);
                alert.error('No licenses assigned to your e-mail found.');
            }
            return setLoading(false);
        } else {
            return alert.error('Error getting licenses');
        }
    }

    useEffect(
        () => {
            getLicenses();
        },
        []
    )
    const getThemes = async () => {
        setLoading(true);
        let res = await auth.ajax(MANAGER_URL + '/themes');
        setLoading(false);
        if (!res.ok) {
            return alert.error(res.error);
        }
        setThemes(res.themes);
    }

    useEffect(() => {
        getThemes();
    }, [])

    const hasValidLicense = themes.length > 0 &&
        licenses &&
        licenses.claimed.length !== 0 &&
        licenses.claimed.every(license => !license.on_hold) &&
        licenses.claimed.every(license => !license.expired) &&
        licenses.claimed.every(license => !license.unclaimed);

    return <React.Fragment>
        <React.Fragment>
            <div className="page__content">
                {
                    hasValidLicense ?
                        <>
                            <Heading loading={loading} text={`Themes`}>
                            </Heading>
                            <Heading margin secondary text={`Available themes`}/>
                            <div className="page__content">
                                <Table header={['ID', 'URL', 'Version', 'Added', 'Download']}>
                                    {
                                        themes.sort((a, b) => (a.added < b.added) ? 1 : -1).map((v) => {
                                            return (
                                                <tr>
                                                    <td>{v.id}</td>
                                                    <td><a href={v.file}>{v.file.slice(0, 24)}..</a></td>
                                                    <td>{v.version}</td>
                                                    <td>{new Date(parseInt(v.added)).toLocaleString()}</td>
                                                    <td><a href={v.file}><BsDownload/></a></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </Table>
                            </div>
                        </>
                        : <Heading loading={loading} text={`No Active Licence Found`} />
                }
            </div>
        </React.Fragment>
    </React.Fragment>
}

export {
    ManageThemes
}