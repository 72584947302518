import Cookies from 'js-cookie';
import {BehaviorSubject} from 'rxjs';
import {API_URL} from 'config';
import { alert } from 'alert';

class Auth{
    constructor(){
        this._initUser = JSON.parse(localStorage.getItem('currentUser')) || false;
        this.userSubject = new BehaviorSubject(this._initUser);
        this.access = Cookies.get('accessJWT') || '';
        this.initUser();
    }

    async reqLogin(data){
        let res;
        try {
            res = await fetch(API_URL+'/v1/customerAuth', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            });
        }catch(err){
            return {ok: false, error: 'A network error occured.'};
        }
        if(!res.ok){
            return {ok: false, error: 'Invalid credentials. Try again.'};
        }
        let json = await res.json();
        if(!json.ok){
            return json;
        }
        return {ok: true};
    }

    async login(token){
        let res;
        try {
            res = await fetch(API_URL+'/v1/customerAuth/' + token, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        }catch(err){
            return {ok: false, error: 'A network error occured.'};
        }
        if(!res.ok){
            return {ok: false, error: 'Invalid token. Check your e-mail.'};
        }
        let json = await res.json();
        if(!json.ok){
            return json;
        }  
        this.setUser({username: json.user});
        this.setAccess(json.access);
        return {ok: true};
    }

    logout(){
        this.removeUser();
        this.removeAccess();
    }

    async initUser(){
        if(this._initUser){
            return;
        }
        // let data = await this.ajax(API_URL+'/v1/customerAuth/customer');
        // if(!data.ok){
        //     return;
        // }
        // this.userSubject.next(data.user);
    }

    setAccess(token){
        this.access = token;
        Cookies.set('accessJWT', token);
    }

    setUser(user){
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.userSubject.next(user);
    }

    removeAccess(){
        this.access = false;
        Cookies.remove('accessJWT');
    }

    removeUser(){
        localStorage.removeItem('currentUser');
        this.userSubject.next(false);
    }

    getHeaders(){
        if(!this.access){
            return {};
        }
        return {'Authorization': `Bearer ${this.access}`}
    }

    async ajax(url, config = {}){
        config.headers = this.getHeaders();
        if(config.method == 'POST'){
            config.headers["Content-Type"] = "application/json";
        }
        let res;
        try{
            res = await fetch(url, config);
        }catch(err){
            return {ok: false, error: 'Network error.'};
        }
        if(!res.ok){
            if(res.status == 401){
                this.logout();
                return {ok: false, error: 'Unauthenticated.'};
            }
            try{
                let data = await res.json();
                let error = data.error || res.status;
                return {ok: false, error}
            }catch(e){
                return {ok: false, error: res.status};
            }
        }
        let data = await res.json();
        if(!data.ok){
            if(data.error == 'TOKEN_EXPIRED'){
                alert.error('Your session expired. Login again to continue.');
                this.logout();
                return {ok: false, error: 'Your session expired. Login again to continue.'};
            }
        }
        return data;
    }
}

const authService = new Auth();

export {
    authService
}