import {notify} from 'react-notify-toast';

class Alert{
    constructor(){
    }

    success(message){
        notify.hide();
        notify.show(message, 'success', 5000);
    }

    error(message){
        notify.hide();
        notify.show(message, 'error', 5000);
    }
    
    warning(message){
        notify.hide();
        notify.show(message, 'warning', 5000);
    }
}

const alert = new Alert();

export {
    alert
}