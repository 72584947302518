let API_URL = 'https://theme.boostertheme.com';
let MANAGER_URL = 'https://manager.boostertheme.com';

if (process.env.NODE_ENV === 'development'){
     API_URL = 'http://localhost:8081';
     MANAGER_URL = 'http://localhost:3001';
}

export {
    API_URL,
    MANAGER_URL
}